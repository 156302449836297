<template>
  <div class="custom-row mt-5">
    <div
      v-for="(question,qIndex) in modelData.questions"
      :key="qIndex"
      class="custom-col"
    >
      <va-card>
        <BarChart
          :chart-data="modelData.chartData[qIndex]"
          :chart-options="chartOptions"
          class="barGraph"
        />
      </va-card>
    </div>
  </div>
</template>
<script>
import BarChart from './graphs/Bar.vue';
export default {
  name: 'QuestionBarChart',
  data: function() {
    return {
      items: [
        { id: 1, content: 'Item 1' },
        { id: 2, content: 'Item 2' },
        { id: 3, content: 'Item 3' },
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: 100,
          },
          x: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            labels: {
              boxWidth: 0,
              font: {
                size: 16,
                weight: 'bold',
              },
            },
          },
          tooltip: {
            callbacks: { 
              //label title
              title: function(context) {
                return `${context[0].dataset?.data[context[0].parsed.x].fullName}`
              },
              beforeLabel: function(context) {
                let aftTitle = '';
                if (context.dataset.data[context.parsed.x].correct) {
                  aftTitle = 'Correct Answer';
                } else {
                  aftTitle = 'Incorrect Answer';
                }
                
                return aftTitle;
              },
              label: function(context) {
                return '% of responses: ' + context.formattedValue + '%';
              },
              afterLabel: function(context) {
                return '# of responses: ' + context.dataset.data[context.parsed.x].responses;
              },

            },
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            titleColor: 'rgba(0, 0, 0, 0.9)',
            bodyColor: 'rgba(0, 0, 0, 0.9)',
            borderColor: 'rgba(0, 0, 0, 0.25)',
            borderWidth: 1,
            displayColors: false,
          },
        },
      },
      chartData: {
        datasets: [{
          label: '',
          backgroundColor: [],
          data: [],
        }],
      },
      barColors: {
        correct: 'rgba(75, 192, 192, 0.7)', // Green
        incorrect: 'rgba(255, 99, 132, 0.7)', // Red
      },
      modelData: {},
    };
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    i18n: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BarChart,
  },
  methods: {
    copyObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    createModelData() { // TODO: Only need to create the model data for a selected group if it has not already been counted. Add check and don't count if it is not needed.
      const retObj = {};
      const chartDatasets = [];
      this.model.questions.forEach((question, qIndex)=> {
        const data = this.copyObject(this.chartData);
        data.datasets[0].label = `${this.i18n[question.question]}`
        question.answers.forEach((answer, aIndex)=>{
          let correct = false;
          if (question.correctAnswer == aIndex) {
            data.datasets[0].backgroundColor.push(this.barColors.correct); // Green color, correct
            correct = true;

            let totalResponses = 0;
            this.model.selectedTimeGroups.forEach((group)=>{
              totalResponses += answer.responses[group];
            });
            question.totalCorrectResponses = totalResponses;
          } else {
            data.datasets[0].backgroundColor.push(this.barColors.incorrect); // Red color, incorrect
          }
          let answerResponsesGroup = 0;
          let questionTotalResponses = 0;
          this.model.selectedTimeGroups.forEach((group)=>{
            answerResponsesGroup += answer.responses[group];
            questionTotalResponses += question.totalResponses[group];
          });
          data.datasets[0].data.push({
            x: this.i18n[`${answer.answer}_abbrv`] || answer.answer,
            y: (answerResponsesGroup/questionTotalResponses)*100,
            name: answer.answer,
            fullName: this.i18n[answer.answer] || answer.answer,
            responses: answerResponsesGroup, correct: correct, 
          });
        });
        chartDatasets.push(data);
      });
      retObj.chartData = chartDatasets;
      retObj.chartOptions = this.copyObject(this.chartOptions);
      retObj.questions = this.model.questions;
      return retObj;
    },
  },
  mounted() {
    this.modelData = this.createModelData();
  },
  watch: {
    model: {
      handler() {
        this.modelData = this.createModelData();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
  .barGraph {
    max-height: 300px;
  }

  .custom-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .custom-col {
    flex: 0 0 calc(33.333% - 10px); /* Ajuste a largura da coluna conforme necessário */
    margin-bottom: 20px; /* Espaçamento entre as colunas */
    margin-left: 5px;
  }
</style>
