<template>
  <div>
    <va-card
      class="card flex xs12 md12 lg12"
      title="Patient"
    >
      <va-item>
        <va-item-section avatar>
          <va-avatar>
            <img
              :src="model.avatarImage.image"
              :alt="model.patientName"
            >
          </va-avatar>
        </va-item-section>
        <va-item-section>
          <va-item-label>
            Patient Name: {{ model.patientName }}
          </va-item-label>
          <va-item-label>
            Model Identifier: {{ model.name }}
          </va-item-label>
          <va-item-label caption>
            <va-badge
              class="mb-2"
              color="success"
              v-if="model.active"
            >
              Active
            </va-badge>
            <va-badge
              class="mb-2"
              color="danger"
              v-else
            >
              Inactive
            </va-badge>
          </va-item-label>
        </va-item-section>
      </va-item>
    </va-card>
  </div>
</template>

<script>
export default {
  name: 'ModelCard',
  data: function() {
    return {

    };
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  components: {
  },
  methods: {
  },
};
</script>

<style>
</style>
