<template>
  <div>
    <va-card
      class="card mb-2"
      title="Information"
    >
      <va-item>
        <va-item-section>
          <va-item-label>
            ID : {{ model.id }}
          </va-item-label>
          <va-item-label>
            Created : {{ model.created }}
          </va-item-label>
          <va-item-label>
            Age: {{ model.age }}
          </va-item-label>
          <va-item-label>
            Total # of Questions: {{ model.questions.length }}
          </va-item-label>
          <va-item-label>
            Total # of Responses : {{ totalNumberOfResponses }}
          </va-item-label>
        </va-item-section>
      </va-item>
    </va-card>
  </div>
</template>

<script>
export default {
  name: 'ModelInformation',
  data: function() {
    return {

    };
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  components: {
  },
  methods: {
  },
  computed: {
    totalNumberOfResponses() {
      let retVal = 0;
      this.model.selectedTimeGroups.forEach((group)=>{
        retVal += this.model.questions[0].totalResponses[group];
      });

      return retVal;
    },
  },
};
</script>

<style>
</style>
