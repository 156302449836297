<template>
  <div>
    <va-card class="mb-2" title="Filter">
      <va-item>
        <div
          class="sm:mx-4 sm:my-4 sm:px-4 sm:py-4 md:mx-auto md:my-8 md:px-8 md:py-8"
        >
          <span> Last Refreshed: <b>{{ lastRefresh }}</b> </span>
          <va-button @click="refreshData"> Refresh Data </va-button>
        </div>
      </va-item>

      <va-item>
        <va-item-section>
          <va-toggle :label="answeredOnlyText" v-model="answeredOnly" />
        </va-item-section>
      </va-item>

      <va-item>
        <va-item-section>
          <va-toggle :label="firstTimeOnlyText" v-model="firstTimeOnly" />
        </va-item-section>
      </va-item>

      <label>Time taken to complete exam: </label>
      <va-item
        v-for="(time, index) in selectedTimes"
        :key="index"
        :value="time.value"
      >
        <va-item-section side>
          <va-checkbox v-model="time.value" />
        </va-item-section>

        <va-item-section>
          <va-item-label>
            {{ time.text }}
          </va-item-label>
        </va-item-section>
      </va-item>
    </va-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import axios from '@/scripts/interceptor.js';
export default {
  name: "ModelFilter",
  data: function () {
    return {
      answeredOnlyText: "Answered Only",
      answeredOnly: true,
      firstTimeOnlyText: "First Time Only",
      firstTimeOnly: true,
      lastRefresh: "2021-09-01 12:00:00",
      selectedTimes: {
        all: {
          text: "All",
          value: true,
        },
        t1: {
          text: "< 1 hour",
          value: false,
        },
        t2: {
          text: "< 2 hours",
          value: false,
        },
        t3: {
          text: "< 3 hours",
          value: false,
        },
        t4: {
          text: "> 3 hours",
          value: false,
        },
      },
    };
  },
  props: {},
  emits: ["update:updateInput", "update:updateTimes", "update:updateFirstTime"],
  components: {},
  mounted() {
    for (const time in this.selectedTimes) {
      if (time == "all") {
        this.$watch("selectedTimes." + time + ".value", (newVal, oldVal) => {
          if (newVal) {
            for (const time in this.selectedTimes) {
              if (time != "all") {
                this.selectedTimes[time].value = false;
              }
            }
          }
          this.$emit("updateTimes", this.selectedTimes);
        });
      } else {
        this.$watch("selectedTimes." + time + ".value", (newVal, oldVal) => {
          if (newVal) {
            this.selectedTimes.all.value = false;
          }
          if (!this.selectedTimes.all.value) {
            this.$emit("updateTimes", this.selectedTimes);
          }
        });
      }
    }
  },
  async created() {
   const lastRefresh = await firebase
      .firestore()
      .collection("patientStatistics")
      .doc("English")
      .get()

    this.lastRefresh = lastRefresh.data().last_refreshed;
    if(this.lastRefresh.seconds){
      this.lastRefresh = new Date(this.lastRefresh.seconds * 1000).toLocaleString();
    }
  },
  methods: {
    async refreshData() {
       //swal loading
       this.$swal.fire({
        title: "Refreshing Data",
        html: "Please wait while the data is being refreshed",
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      const latest_lastRefresh = await firebase
        .firestore()
        .collection("patientStatistics")
        .doc("English")
        .get()
      //check if last refresh is less than 5 minutes
      if(latest_lastRefresh.data().last_refreshed.seconds){
        const lastRefreshed = latest_lastRefresh.data().last_refreshed.seconds;
        const currentTime = new Date().getTime() / 1000;
        if(currentTime - lastRefreshed < 300){
          this.$swal.fire({
            title: "Warning",
            text: "Please wait at least 5 minutes before refreshing data",
            icon: "warning",
            timer: 2000,
            timerProgressBar: true,
          });
          return;
        }
      }
      axios
        .post("api/patients/queue/fetch/latest")
        .then((response) => {
          this.$swal.fire({
            title: "Refresh Enqueued",
            icon: "info",
            html: "Your request has been enqueued and will be processed shortly, please, wait a few minutes and refresh the page to see the updated data.",
          });
          this.lastRefresh = new Date().toLocaleString();
        })
        .catch((error) => {
          this.$swal.fire({
            title: "Error",
            text: "Error refreshing data",
            icon: "error",
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },
  },
  watch: {
    answeredOnly(newAnswer, oldAnswer) {
      this.answeredOnlyText = "All Models";

      if (newAnswer) {
        this.answeredOnlyText = "Answered Only";
      }

      this.$emit("updateInput", this.answeredOnly);
    },
    firstTimeOnly(newAnswer, oldAnswer) {
      this.firstTimeOnlyText = "First Time Only";

      if (!newAnswer) {
        this.firstTimeOnlyText = "All Responses";
      }

      this.$emit("updateFirstTime", this.firstTimeOnly);
    },
  },
};
</script>

<style>
.va-checkbox__input-container {
  margin: 0 auto;
}
</style>
