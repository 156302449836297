<template>
  <div>
    <va-list class="modelSelectionList">
      <va-list-label class="text-left">
        Model Selection
      </va-list-label>
      <div
        v-for="(modelA, index) in getModels"
        :key="index"
        :value="modelA"
      >
        <va-item
          clickable
          @click="changeModel(index)"
        >
          <va-item-section side>
            <va-icon
              name="fa fa-bar-chart"
              color="green"
              v-if="modelA.selected"
            />
            <va-icon
              name="fa fa-bar-chart"
              v-else
            />
          </va-item-section>

          <va-item-section avatar>
            <va-avatar>
              <img
                :src="modelA.avatarImage.image"
                :alt="modelA.patientName"
              >
            </va-avatar>
          </va-item-section>

          <va-item-section>
            <va-item-label>
              {{ modelA.patientName }}
            </va-item-label>
          </va-item-section>

          <va-item-section>
            <va-item-label>
              {{ modelA.name }}
            </va-item-label>
          </va-item-section>
        </va-item>

        <va-list-separator
          v-if="index < getModels.length - 1"
          :key="'separator' + modelA.id"
        />
      </div>
    </va-list>
  </div>
</template>

<script>
export default {
  name: 'ModelSelection',
  data: function() {
    return {
      selectedModel: {},
    };
  },
  emits: ['update:selectedModel'],
  props: {
    models: {
      type: Object,
      default: () => {},
    },
    answeredOnly: {
      type: Boolean,
      default: true,
    },
  },
  components: {
  },
  mounted() {
    this.selectModel();
  },
  methods: {
    changeModel(index) {
      this.selectedModel.selected = false;
      this.selectedModel = this.getModels[index];
      this.selectedModel.selected = true;
      this.$emit('selectedModel', this.selectedModel);
    },
    selectModel() {
      for (const model in this.getModels) {
        if (this.answeredOnly) {
          if (this.getModels[model].questions[0].totalResponses.all > 0) {
            this.selectedModel = this.getModels[model];
            this.selectedModel.selected = true;
            return;
          }
        }
      }
    },
  },
  computed: {
    getModels() {
      const compModels = {};
      for (const model in this.models) {
        if (this.answeredOnly) {
          if (this.models[model].questions[0].totalResponses.all > 0) {
            compModels[model] = this.models[model];
          }
        } else {
          compModels[model] = this.models[model];
        }
      }
      return compModels;
    },
  },
  watch: {
    getModels(newVal, oldVal) {
      for (const firstModel in newVal) {
        if (firstModel.length > 0) {
          this.changeModel(firstModel);
          break;
        }
      }
    },
  },
};
</script>

<style>
  .modelSelectionList > .va-card__body {
    max-height: 225px !important;
    min-height: 225px !important;
  }
</style>
