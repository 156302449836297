<template>
  <div class="row">
    <va-card
      class="card flex xs12 md12 lg12 questionTable"
      title="Question Stats (Hover for Details)"
    >
      <table
        class="va-table va-table--striped"
        :height="400"
      >
        <thead>
          <tr>
            <th>Question</th>
            <th>Correct Answer</th>
            <th>Total Responses</th>
            <th>Pass Rate</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(question,qIndex) in model.questions"
            :key="qIndex"
          >
            <td style="cursor: pointer;" :title="`Answer: ${i18n[question.question]}`">{{ question.question }}</td>
            <td style="cursor: pointer;" :title="`Answer: ${i18n[question.answers[question.correctAnswer].answer]}`">{{ `${question.answers[question.correctAnswer].answer}` }}</td>
            <td>{{ totalQuestionResponses(question) }}</td>
            <td>{{ formatPercent(question.totalCorrectResponses,totalQuestionResponses(question)) }}%</td>
          </tr>
        </tbody>
      </table>
    </va-card>
  </div>
</template>

<script>
export default {
  name: 'QuestionTable',
  data: function() {
    return {

    };
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    i18n: {
      type: Object,
      default: () => {},
    },
  },
  components: {
  },
  methods: {
    formatPercent(correct, total) {
      let formattedNumber = 0;
      if (total > 0) {
        formattedNumber = ((correct/total) * 100).toFixed(2);
      }
      return formattedNumber;
    },
    totalQuestionResponses(question) {
      let retVal = 0;

      this.model.selectedTimeGroups.forEach((group)=>{
        retVal += question.totalResponses[group];
      });

      return retVal;
    },
  },
  computed: {

  },
};
</script>

<style>
  .questionTable > .va-card__body {
    max-height: 400px !important;
    min-height: 250px !important;
  }
</style>
